import React, { useEffect, useState } from 'react';
import { StateApi } from "../state";
import { LandlordApi } from "../landlord";
import { MediaSeriesApi } from "../series";
import { MediaTypeApi } from "../mediatype";
import { MediaLitApi } from "../littype";
import { message } from '../../../components/toast';
import { uuid } from '../../../utils/generalUtils';
import { RouteApi } from '../route';
import { useApi } from '../../../helper/apiHook';
import { AgencyApi } from '../agency';
import {DropdownToggle,Dropdown,DropdownMenu,DropdownItem, Badge,} from "reactstrap";
import { UPDATE } from '../../../constant/commonConst';
const CityApi = (data?: any) => {
  return { ...useApi("/cities", false, data, { revalidateIfStale: true }) };
};
const ZoneApi = (data?: any) => {
  return { ...useApi("/zones", false, data, { revalidateIfStale: true }) };
};
const LocationApi = (data?: any) => {
  return { ...useApi("/locations", false, data, { revalidateIfStale: true }) };
};
export default function apiHelper(selectedLocation?: any) {
  const stateData = StateApi(false, { revalidateIfStale: true });
  let cityData = CityApi(selectedLocation?.stateId && { stateId: selectedLocation?.stateId });
  const zoneData = ZoneApi(selectedLocation?.cityId && { cityId: selectedLocation?.cityId });
  const locationData = LocationApi(selectedLocation?.cityId && { cityId: selectedLocation?.cityId });
  const landlordData = LandlordApi(false, { revalidateIfStale: true });
  const mediaTypeData = MediaTypeApi(false, { revalidateIfStale: true });
  const mediaLitData = MediaLitApi(false, { revalidateIfStale: true });
  const agencyData = AgencyApi(false, { revalidateIfStale: true });
  const mediaSeriesData = MediaSeriesApi(false, { revalidateIfStale: true });
  const routeData = RouteApi(false, { revalidateIfStale: true });
  let stateList = stateData?.data?.data?.length > 0 ? stateData.data.data.map((item: any) => { return { value: item.id, label: item.name } }) : [];
  let cityList = cityData?.data?.data?.length > 0 ? cityData?.data?.data?.map((item: any) => { return { value: item.id, label: item.name } }) : [];
  let zoneList = zoneData?.data?.data?.length > 0 ? zoneData?.data.data?.map((item: any) => { return { value: item.id, label: item.name, description: item.description } }) : [];
  let locationList = locationData?.data?.data?.length > 0 ? locationData?.data.data?.map((item: any) => { return { value: item.id, label: item.name, data: { ...item }, description: item.description } }) : [];
  let landlordList = landlordData?.data?.data?.length > 0 ? landlordData?.data.data?.map((item: any) => { return { value: item.id, label: item.name } }) : [];
  let mediaTypeList = mediaTypeData?.data?.data?.length > 0 ? mediaTypeData?.data.data?.map((item: any) => { return { value: item.id, label: `${item.name} - ${item.type}`, type: item.type } }) : [];
  let mediaLitList = mediaLitData?.data?.data?.length > 0 ? mediaLitData?.data.data?.map((item: any) => { return { value: item.id, label: item.name } }) : [];
  let mediaSeriesList = mediaSeriesData?.data?.data?.length > 0 ? mediaSeriesData?.data.data?.map((item: any) => { return { value: item.id, label: item.name } }) : [];
  let routeList = routeData?.data?.data?.length > 0 ? routeData?.data.data?.map((item: any) => { return { value: item.id, label: item.name, data: { ...item } } }) : [];
  let agencyList = agencyData?.data?.data?.length > 0 ? agencyData?.data.data?.map((item: any) => { return { value: item.id, label: item.name,data: { ...item } } }) : [];

  return {
    stateList,
    cityList,
    zoneList,
    locationList,
    landlordList,
    mediaTypeList,
    mediaLitList,
    locationData,
    landlordData,
    agencyData,
    routeData,
    mediaSeriesList, routeList, agencyList
  };
}
interface ImagePreview {
  isPrimary: boolean;
  id: string;
  url?: string;
  name?: string;
  path?:string;
}

export const useUpload = (maxSize: number) => {
  const [imagePreviews, setImagePreviews] = useState<ImagePreview[]>([]);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const handlefileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) return;

    const files = Array.from(e?.target?.files);

    // Restrict the number of images to 4
    if (selectedImages.length + files.length > maxSize) {
      message('error', `You can only upload a maximum of ${maxSize} images.`);
      return;
    }

    setSelectedImages((prevImages) => [...prevImages, ...files]);

    const filePreviews = files.map((file) => ({
      isPrimary: false,
      id: uuid(),
      url: URL.createObjectURL(file),
    }));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...filePreviews]);
  };


  const handleRemoveImage = (index: number) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleSetPrimaryImage = (index: number) => {
    setImagePreviews((prevPreviews) =>
      prevPreviews.map((preview, i) => ({
        ...preview,
        isPrimary: i === index,
      }))
    );
  };

  return {
    handlefileChange,
    handleRemoveImage,
    handleSetPrimaryImage,
    imagePreviews,
    selectedImages,
    setImagePreviews,
    setSelectedImages,
  };
};

export const StatusDropDown = (props: any) => {
  const { column, rowData } = props;
  const statusOptions = [
    {
      label: "Available",
      value: "Available",
      color: "success",
    },
    {
      label: "Booked",
      value: "Booked",
      color: "primary",
    },
    {
      label: "Blocked",
      value: "Blocked",
      color: "warning",
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE });
    setDropdownOpen(false);
  };
  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={
              statusOptions.find((option) => option.value === rowData.status)
                ?.color
            }
            className="text-dark"
          >
            {
              statusOptions.find((option) => option.value === rowData.status)
                ?.label
            }
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}{" "}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};